import { json, type LoaderFunctionArgs, type MetaFunction } from "@remix-run/node";
import NotFound from "~/components/NotFound";
import i18n from "~/i18next.server";

export const meta: MetaFunction<typeof loader> = ({ data }) => {
    if (!data) return [];

    return [
        { title: data.meta.title },
        {
            name: "description",
            content: data.meta.description
        },
        {
            name: "keywords",
            content: data.meta.keywords
        },
        {
            property: "og:title",
            content: data.meta.title
        },
        {
            property: "og:description",
            content: data.meta.description
        }
    ];
};

export const headers = {
    "Cache-Control": "public, max-age=2592000, s-maxage=1296000",
};

export const handle = {
    i18n: "common"
};

export async function loader({ request }: LoaderFunctionArgs) {
    const t = await i18n.getFixedT(request);

    return json({
        meta: {
            title: t('meta.404.title'),
            description: t('meta.404.description'),
            keywords: t('meta.404.keywords')
        }
    });
}

export default function CatchAllRoute() {
    return <NotFound />;
} 