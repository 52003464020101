import { json, Link } from "@remix-run/react";
import { useTranslation } from "react-i18next";

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <div className="min-h-[70vh] flex flex-col items-center justify-center px-4">
      <div className="text-center">
        <div className="mb-8">
          <div className="text-purple-500 text-9xl font-bold mb-4">404</div>
          <h1 className="text-2xl font-semibold text-slate-200 mb-2">
            {t('404.title')}
          </h1>
          <p className="text-slate-400">
            {t('404.message')}
          </p>
        </div>

        <Link
          to="/"
          className="inline-flex items-center text-purple-500 hover:text-purple-400 transition duration-150 ease-in-out group"
        >
          {t('backToHome')}
          <span className="tracking-normal group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">
            →
          </span>
        </Link>
      </div>
    </div>
  );
} 